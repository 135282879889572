export const CreateMessageModalProps = {
    title: "צור הודעה",
    saveButtonText: "שלח",
    closeButtonText: "בטל",
    size: "lg",
    fields: [
        {
            as: "dropdown",
            options: [
                {label: "כללי", value: "general"},
                {label: "הערה", value: "error"},
                {label: "תקנון", value: "info"},
                {label: "שימוע", value: "warning"},
                {label: "הצטיינות", value: "success"},
                {label: "שיחה אישית", value: "primary"},
                ],
            size: "sm",
            type: "text",
            label: "סוג הודעה",
            value: "general",
            name: "type",
            required: true,
        },
        {
            as: "dropdown",
            options: [
                {label: "הסתר מהתלמיד", value: "false"},
                {label: "הצג לתלמיד", value: "true"},
            ],
            size: "sm",
            type: "text",
            label: "הצג לתלמיד",
            value: "false",
            name: "show_to_student",
            required: false,
        },
        {
            as: "textarea",
            type: "text",
            label: "הודעה",
            placeholder: "הקלד הודעה",
            name: "message",
            required: true,
        },
    ]
}

export const CreateMessageReplyModalProps = {
    title: "השב להודעה",
    saveButtonText: "שלח",
    closeButtonText: "בטל",
    size: "lg",
    fields: [
        {
            as: "textarea",
            type: "text",
            size: "lg",
            label: "הודעה",
            placeholder: "הקלד הודעה",
            name: "message",
            required: true,
        },
    ]
}
