import {Message} from "../Message/Message";
import React, {useCallback, useEffect, useState} from "react";
import {getPersonalMessagesReplies} from "../../../Db/DataBase";
import "./ReplyAbleMessage.css";

export function ReplyAbleMessage({userProps, messageId, children, getMessageVisibilityIcon, handleMarkAsRead, deleteMode, onDelete}) {
    const [replies, setReplies] = useState([]);
    useEffect(() => {
        getPersonalMessagesReplies(messageId).then((replies) => {
            setReplies(replies.map((reply) => {
                return {
                    message: reply.message,
                    creatorUuid: reply.creator_uuid,
                    title: reply.creator_name,
                    timestamp: reply.creation_time,
                    type: reply.type,
                    id: reply.message_uuid,
                    visible: reply.visible,
                    isRead: reply.is_read
                }
            }));
        })
    }, [messageId]);

    const onDeleteMessage = useCallback((messageId) => {
        const filteredReplies = replies.filter((reply) => {
            return reply.id !== messageId
        });
        setReplies(replies.filter((reply) => {
            return reply.id !== messageId
        }));
        onDelete(messageId);
    }, [replies, onDelete]);

    const getDeleteButton = (message) => {
        if (!deleteMode || message.creatorUuid !== userProps.uuid) return null;
        return <div className={'align-self-center'}>
            <button className={"btn rounded-3 action-svg"} onClick={() => onDeleteMessage(message.id)}>
                <a className={'text-danger fa fa-minus-circle fa-2x action-svg'}></a>
            </button>
        </div>;
    }

    return (<div>
            {children}
            {replies.length > 0 && <div className={'d-flex mx-md-3 mx-0 flex-column gap-2 overflow-auto'}
                                        style={{maxHeight: "250px", width: "90%", borderLeft: "1px black solid"}}>
                {replies.map((reply) => {
                    return (<div key={'reply-' + reply.id} className={'d-flex flex-row align-content-center gap-0'}>
                        {getDeleteButton(reply)}
                        <div className={'flex-grow-1'} >
                            <Message message={reply.message} title={reply.title}
                                     timestamp={reply.timestamp}
                                     type={'comment'}
                                     titleIcon={getMessageVisibilityIcon(reply)}
                                     iconToolTip={parseInt(reply.visible) ? "התלמיד רואה את הודעה" : "התלמיד אינו רואה את הודעה"}
                                     onMessageRead={handleMarkAsRead}
                                     messageId={reply.id}
                                     isRead={parseInt(reply.isRead) === 1}
                            />
                        </div>
                    </div>)
                })}
            </div>}
        </div>

    )
}