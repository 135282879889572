import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";


export function GenericModal({show, handleClose, handleSave, modalProps}) {

    const saveBtnRef = useRef(null);

    const [formData, setFormData] = useState(
        modalProps.fields.reduce((acc, field) => {
            acc[field.name] = field.value || '';
            return acc;
        }, {})
    );

    useEffect(() => {
        setFormData(
            modalProps.fields.reduce((acc, field) => {
                acc[field.name] = field.value || '';
                return acc;
            }, {})
        );
    }, [show]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onSave = () => {
        saveBtnRef.current.setAttribute("disabled", "disabled");
        handleSave(formData);
    };

    const getSizeClass = (size) => {
        switch (size) {
            case 'sm':
                return 'modal-sm';
            case 'me':
                return 'modal-me';
            case 'lg':
                return 'modal-lg';
            case 'xl':
                return 'modal-xl';
            default:
                return '';
        }
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName={getSizeClass(modalProps.size)}>
            <Modal.Header>
                <Modal.Title>{modalProps.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formField">
                        <Row className="g-2">
                            {modalProps.fields.map((field, index) => {
                                return (
                                    <Col key={index} xs={field.size === 'sm' ? 6 : 12}>
                                        <Form.Label column={field.name}>{field.label}</Form.Label>
                                        {field.as === 'dropdown' ?
                                            <Form.Select name={field.name}
                                                         onChange={handleChange}
                                            >
                                                {field.options.map((option, index) => {
                                                    return (
                                                        <option key={index+field.name} value={option.value}>{option.label}</option>
                                                    )
                                                })}
                                            </Form.Select> :
                                            <Form.Control size={field.size}
                                                          as={field.as}
                                                          type={field.type}
                                                          placeholder={field.placeholder}
                                                          value={formData[field.name]}
                                                          onChange={handleChange}
                                                          name={field.name}
                                                          rows={field.as === 'textarea' ? 8 : undefined}
                                            />
                                        }
                                    </Col>
                                )
                            })}
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {modalProps.closeButtonText}
                </Button>
                <Button variant="primary" ref={saveBtnRef} onClick={onSave}>
                    {modalProps.saveButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}