import {Message} from "../Message/Message";
import "./MessagesBox.css";
import React, {useCallback, useState} from "react";
import {createPersonalMessage, deletePersonalMessage, personalMessageRead} from "../../../Db/DataBase";
import {toast} from "react-toastify";
import {GenericModal} from "../../GenericModal/GenericModal";
import {CreateMessageModalProps, CreateMessageReplyModalProps} from "../consts/createMessageModalProps";
import HashLoader from "react-spinners/HashLoader";
import {ReplyAbleMessage} from "../ReplyAbleMessage/ReplyAbleMessage";

export function MessageBox({
                               fetchMessages,
                               messages,
                               selectedStudent,
                               setMessages,
                               userProps = {},
                               showActionButtons = false,
                               loading = false
                           }) {
    const [deleteMode, setDeleteMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [replyModal, setReplyModal] = useState(false);
    const [replyMessageId, setReplyMessageId] = useState(null);

    const toggleDeleteMode = useCallback(() => {
        setDeleteMode((prevMode) => !prevMode);
    }, []);
    const toggleEditMode = useCallback(() => {
        setEditMode((prevMode) => !prevMode);
    }, []);
    const toggleReplyModal = useCallback((messageId) => {
        setReplyMessageId(messageId);
        setReplyModal((prevMode) => !prevMode);
    }, []);

    const handleSave = useCallback((formData) => {
        createPersonalMessage(userProps.email, userProps.password, selectedStudent.uuid, formData.message, userProps.uuid, formData.type, userProps.first_name + " " + userProps.last_name, formData.show_to_student, -1).then(() => {
            toast("ההודעה נוצרה בהצלחה", {type: "success"});
            setEditMode(false);
            fetchMessages();
        });
    }, [fetchMessages, selectedStudent.uuid, userProps.email, userProps.first_name, userProps.last_name, userProps.password, userProps.uuid]);

    const getEditButtons = useCallback(() => {
        if (showActionButtons) {
            return (
                <div className={" d-flex flex-row gap-2 justify-content-between"}>
                    <h4 className={'d-flex align-items-center m-0'}> תיק אישי
                        - {selectedStudent.first_name} {selectedStudent.last_name}</h4>
                    <div className={'p-1 me-1 d-flex flex-row gap-2 '}>
                        {['admin', 'operator', 'teacher'].includes(userProps.admin) && <button className={"btn bg-darkblue rounded-3 action-svg text-white"} onClick={toggleEditMode}>
                            <a className={'fa fa-add'}></a></button>}
                        <button className={"btn bg-darkblue rounded-3 action-svg text-white"}
                                onClick={toggleDeleteMode}>
                            <a className={'fa fa-trash'}></a></button>
                    </div>
                </div>
            )
        }
        return null;
    }, [userProps, showActionButtons, selectedStudent.first_name, selectedStudent.last_name, toggleEditMode, toggleDeleteMode]);

    const deleteMessage = useCallback((messageId) => {
        deletePersonalMessage(userProps.email, userProps.password, messageId).then(() => {
            setMessages((prevMessages) => prevMessages.filter((message) => message.id !== messageId));
            toast("ההודעה נמחקה בהצלחה", {type: "success"});
        });
    }, [userProps]);

    const getMessageVisibilityIcon = useCallback((message) => {
        let messageVisibility = "";
        if (parseInt(message.visible)) {
            messageVisibility = "fa-eye";
        } else {
            messageVisibility = "fa-eye-slash";
        }

        if (parseInt(message.isRead)) {
            messageVisibility += " text-primary";
        }
        return messageVisibility;
    }, []);

    const getDeleteButton = (message) => {
        if (!deleteMode || message.creatorUuid !== userProps.uuid) return null;
        return (
            <div className={'align-self-center align-content-center'} style={{height: '80%',borderLeft: "1px black solid"}} >
                <button className={"btn align-self-center rounded-3 action-svg"} onClick={() => deleteMessage(message.id)}>
                    <a className={'text-danger fa fa-minus-circle fa-2x action-svg'}></a>
                </button>
            </div>
        )
    };

    const handleMarkAsRead = useCallback((messageId) => {
        console.log(selectedStudent.uuid, userProps.uuid, messageId);
        if (selectedStudent.uuid === userProps.uuid) {
            personalMessageRead(userProps.email, userProps.password, messageId).then(() => {
                console.log("Message marked as read");
            });
        } else {
            console.log("Message not marked as read");
        }

    }, [selectedStudent.uuid, userProps.email, userProps.password, userProps.uuid]);

    const handleReply = useCallback((formData) => {
        createPersonalMessage(userProps.email, userProps.password, selectedStudent.uuid, formData.message, userProps.uuid, 'comment', userProps.first_name + " " + userProps.last_name, 'true', replyMessageId).then(() => {
            toast("ההודעה נשלחה בהצלחה", {type: "success"});
            setReplyModal(false);
            setMessages([]);
            fetchMessages();
        });
    }, [replyMessageId]);


    return (
        <div className={"message-box"}>
            <GenericModal show={editMode} modalProps={CreateMessageModalProps} handleClose={toggleEditMode}
                          handleSave={handleSave}></GenericModal>
            <GenericModal show={replyModal} modalProps={CreateMessageReplyModalProps} handleClose={toggleReplyModal}
                          handleSave={handleReply}></GenericModal>
            <div className={"p-2 bg-light shadow rounded-2 border d-fex flex-row"}>
                {getEditButtons()}
            </div>
            {loading ? <div className={'d-flex justify-content-center'}><HashLoader></HashLoader></div> :
                messages.length === 0 && <div className={'p-2 text-center display-4'}>אין הודעות</div>
            }
            {(messages || []).map((message) => (
                <div key={message.timestamp} className={'d-flex flex-row align-content-center gap-0'}>
                    {getDeleteButton(message)}
                    <div className={'flex-grow-1'} >
                        <ReplyAbleMessage userProps={userProps} getMessageVisibilityIcon={getMessageVisibilityIcon} onDelete={deleteMessage}
                                          handleMarkAsRead={handleMarkAsRead} messageId={message.id} deleteMode={deleteMode}>
                            <Message message={message.message} title={message.title}
                                     timestamp={message.timestamp}
                                     type={message.type}
                                     titleIcon={getMessageVisibilityIcon(message)}
                                     iconToolTip={parseInt(message.visible) ? "התלמיד רואה את הודעה" : "התלמיד אינו רואה את הודעה"}
                                     onMessageRead={handleMarkAsRead}
                                     messageId={message.id}
                                     isRead={parseInt(message.isRead) === 1}
                                     onActionClick={() => toggleReplyModal(message.id)}
                            /></ReplyAbleMessage>
                    </div>
                </div>
            ))}
        </div>
    )
}