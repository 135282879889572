import "./personalFile.css";
import {useCallback, useEffect, useState} from "react";
import {getPersonalFileStudents, getPersonalMessages} from "../Db/DataBase";
import {MessageBox} from "../Components/Messages/MessagesBox/MessageBox";
import {StudentsBox} from "./components/StudentsBox/StudentsBox";
import {StudentStatistics} from "./components/StudentStatistics/StudentStatistics";

const admins = ['admin', 'operator', 'teacher'];

export function PersonalFile(userProps) {
    const [messages, setMessages] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(false);
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(userProps.userProps);

    if (userProps.userProps == null)  window.location.replace("/");

    const handleStudentClicked = useCallback((student) => {
        setSelectedStudent(student);
    }, []);

    const fetchMessages = useCallback(() => {
        setMessagesLoading(true);
        getPersonalMessages(userProps.userProps.email, userProps.userProps.password, selectedStudent.uuid).then((messages) => {
            setMessages(messages.map((message) => {
                return {
                    message: message.message,
                    title: message.creator_name,
                    timestamp: message.creation_time,
                    creatorUuid: message.creator_uuid,
                    type: message.type,
                    id: message.message_uuid,
                    visible: message.visible,
                    isRead: message.is_read
                }
            }));
            setMessagesLoading(false);
        });
    }, [selectedStudent.uuid, userProps.userProps.email, userProps.userProps.password]);

    useEffect(() => {
        getPersonalFileStudents(userProps.userProps.email, userProps.userProps.password).then((students) => {
            setStudents(students);
        });
    },[userProps.userProps.email, userProps.userProps.password]);

    useEffect(() => {
        fetchMessages();
    }, [fetchMessages]);

    return (
        <div className="main-container">
            {admins.includes(userProps.userProps.admin) ?
                <StudentsBox students={students} className={'students-container'}
                             handleStudentClicked={handleStudentClicked}
                             selectedStudent={selectedStudent}> </StudentsBox>
                :
                // We couldn't fix it because it was late, and we were tired wink wink we don't know how to code.
                <div className={'weresoobad'}></div>
            }
            <div className="personal-file">
                <MessageBox showActionButtons={true}
                            fetchMessages={fetchMessages}
                            messages={messages}
                            setMessages={setMessages}
                            userProps={userProps.userProps}
                            loading={messagesLoading}
                            selectedStudent={selectedStudent}
                />
                <StudentStatistics userProps={userProps.userProps} student={selectedStudent}/>
            </div>
        </div>
    );
}

