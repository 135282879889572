import './StudentsBox.css';
import {StudentSearch} from "../StudentSearch/StudentSearch";
import StudentCard from "../StudentCard/StudentCard";
import {useCallback, useEffect, useState} from "react";

export const StudentsBox = ({students, handleStudentClicked, className, selectedStudent}) => {
    const [filteredStudents, setFilteredStudents] = useState(students);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        setFilteredStudents(students);
    }, [students]);

    const getAllStudentsCards = useCallback(() => {
        if (filteredStudents.length === 0) {
            return <div className={'no-students-msg'}>התלמיד אינו נמצא</div>
        }
        return filteredStudents.map((student) => {
            return <StudentCard student={student}
                                isSelected={(student.uuid === selectedStudent?.uuid) || false}
                                key={student.uuid}
                                onClickFunc={() => {
                                    handleStudentClicked(student)
                                }}></StudentCard>;
        })
    }, [filteredStudents, handleStudentClicked, selectedStudent?.uuid]);

    return (
        <>
            {!isOpen && <div
                className={'w-38px h-38px position-fixed m-1 pointer fw-point8rem text-white bg-darkblue p-1  rounded-circle d-flex justify-content-center align-items-center'}
                style={{zIndex: 1021}} onClick={() => setIsOpen(true)}>
                <div className={`fa fa-2x fa-arrow-left`}></div>
            </div>}
            <div className={className + ` ${isOpen ? '' : 'transform-student-box'}`}>
                <div className={'students-box'}>
                    <div className={'d-flex pb-1 sticky-top'}>
                        <div onClick={() => setIsOpen(false)} className={'pointer fw-point8rem'}>
                            <div className={`fa fa-2x fa-arrow-right close-box `}></div>
                        </div>
                    </div>
                    <div className={'pb-2 '}>
                        <StudentSearch students={students} filteredState={setFilteredStudents}></StudentSearch>
                    </div>
                    {getAllStudentsCards()}
                </div>
            </div>

            <div className={`students-container-underlay ${isOpen ? '' : 'closed-students-box'}`}></div>
        </>
    );
}