import "./StudentCardInfo.css";

export const StudentCardInfo = ({ student }) => {
    return (
        <div className={'student-card-info'}>
            <div className={'student-content'}>
                <div className={''}>
                    {student.first_name} {student.last_name}
                </div>
                <div className={'font-small'}>
                    התלמיד איחר {student.attended_late_counter} פעמים החודש
                </div>

            </div>
        </div>
    );
}