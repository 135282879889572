import React, {useCallback, useEffect, useState} from "react";
import {fetchDaysInMonth, fetchStudentsClassStatistics, load_data_getClassesForUser} from "../../../Db/DataBase";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import HashLoader from "react-spinners/HashLoader";


export function StudentStatistics({student, userProps}) {
    const [classes, setClasses] = useState([]);
    const [classesStatisticsMap, setClassesStatisticsMap] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const [daysInMonth, setDaysInMonth] = useState(17);
    const [fromMonth, setFromMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toMonth, setToMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1));

    const formatDate = useCallback((date) => {
        return date.toISOString().split('T')[0];
    }, []);

    const shiftMonths = useCallback((offset) => {
        setFromMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + offset, 1));
        setToMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + offset, 1));
    }, []);

    useEffect(() => {
        fetchDaysInMonth().then((daysInMonth) => {
            setDaysInMonth(daysInMonth);
        })
    }, []);

    useEffect(() => {
        fetchClasses();
    }, [student]);

    useEffect(() => {
        fetchStatistics();
    }, [classes, fromMonth]);

    const fetchClasses = useCallback(() => {
        setLoading(true);
        load_data_getClassesForUser(student.uuid).then((classes) => {
            const attendingClasses = [];
            Object.keys(classes).forEach((key) => {
                if (classes[key] === '1') {
                    attendingClasses.push(key);
                }
            });
            setClasses(attendingClasses);
            setLoading(false);
        });
    }, [student]);

    const fetchStatistics = useCallback(() => {
        setClassesStatisticsMap(new Map());
        if (classes.length === 0) {
            return;
        }
        fetchStudentsClassStatistics(userProps.email, userProps.password, student.uuid, classes, formatDate(fromMonth), formatDate(toMonth)).then((statistics) => {
            const statisticsMap = new Map();
            classes.forEach((className) => {
                statisticsMap.set(className, statistics[className] === null ? 0 : statistics[className]);
            });
            setClassesStatisticsMap(statisticsMap);
        });
    }, [classes, formatDate, fromMonth, student.uuid, toMonth, userProps.email, userProps.password]);

    return (
        <div
            className={'d-flex h-100 overflow-auto flex-column rounded-3 bg-light '}>
            <div className={"p-2 border border-1 m-1 rounded-2 d-flex flex-row gap-2 justify-content-center bg-light shadow shadow-md"}>
                <button className={"btn bg-darkblue rounded-3 action-svg text-white"}
                        onClick={() => shiftMonths(1)}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                    <a className={'fa fa-add'}></a></button>
                <h4 className={'d-flex align-items-center m-0'}> {fromMonth.getFullYear()}/{fromMonth.getMonth() + 1}</h4>
                <button className={"btn bg-darkblue rounded-3 action-svg text-white"}
                        onClick={() => shiftMonths(-1)}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                    <a className={'fa fa-minus'}></a></button>
            </div>

            <div className={'d-flex flex-row flex-wrap gap-4 justify-content-center my-4'}>
            {loading ? <HashLoader></HashLoader> :
                Array.from(classesStatisticsMap.keys()).map((className) => {
                    const percentage = (classesStatisticsMap.get(className));
                    return (
                        <div key={className + 'title'} className={'d-flex flex-column justify-content-center'}
                             style={{width: '200px', height: '200px'}}>
                        <h4 className={'text-center'}> {className.replaceAll("_", " ")}</h4>
                            <CircularProgressbar maxValue={daysInMonth} value={percentage}
                                                 text={`${percentage}/${daysInMonth}`}
                                                 styles={buildStyles({
                                                     pathColor: percentage > 5 ? '#ca141a' : percentage > 3 ? '#bc683f' : '#4bc33b',
                                                     textColor: percentage > 5 ? '#ca141a' : percentage > 3 ? '#bc683f' : '#4bc33b',
                                                     trailColor: percentage > 5 ? '#dd8789' : percentage > 3 ? '#eabdaa' : '#96e091',
                                                     pathTransitionDuration: 0.5,
                                                 })}/>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

