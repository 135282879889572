import "./Message.css";
import {getMessageThemes} from "../Theme/messageThemes";
import {useCallback, useEffect, useRef} from "react";
import {Tooltip} from "react-tooltip";


export function Message({message, messageId, isRead = false, type, timestamp, titleIcon, iconToolTip, title = '', onMessageRead, onActionClick}) {

    const messageTheme = getMessageThemes(type);
    const messageRef = useRef(null);

    const getTooltipByType = useCallback(() => {
        switch (type) {
            case "general":
                return "הודעה כללית";
            case "error":
                return "הודעת הערה";
            case "info":
                return "הודעת תקנון";
            case "warning":
                return "הודעת שימוע";
            case "success":
                return "הודעת הצטיינות";
            case "primary":
                return "הודעת שיחה אישית";
            default:
                return "הודעה כללית";
        }
    }, [type]);

    useEffect(() => {
        if (!onMessageRead || isRead) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    onMessageRead(messageId); // Notify parent when message is read
                    observer.disconnect(); // Stop observing
                }
            },
            { threshold: 1.0 }
        );

        if (messageRef.current) {
            observer.observe(messageRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [onMessageRead, messageId]);

    return (
        <>
            <div ref={messageRef} className={"message"} style={{
                borderColor: messageTheme.primaryTextColor,
                backgroundColor: messageTheme.primaryBackGroundColor,
                color: messageTheme.primaryTextColor
            }}>
                <i className={messageTheme.faIcon + " shake"} style={{fontSize: "2.2rem"}}/>
                <div className={'d-flex flex-column w-100'}>
                    <div className={'d-flex flex-md-row flex-column justify-content-between'}>
                        <span data-tooltip-id={timestamp + "-messageTooltip"} data-tooltip-content={""+getTooltipByType()}  className={'fw-bold'}>{title} <span className={titleIcon ? `fa ${titleIcon}` : ''}
                                                                  data-toggle="tooltip" title={iconToolTip}></span></span>
                        <span >{timestamp}</span>
                    </div>
                    <div className={'d-flex flex-row justify-content-between'}>
                        <span>{message}</span>
                        {onActionClick && <div className={'d-flex gap-2 align-items-end'} onClick={()=> onActionClick()}>
                            <span className={'fa fa-reply fa-flip-horizontal'} ></span>
                        </div>}
                    </div>

                </div>
            </div>
            <Tooltip id={timestamp + "-messageTooltip"}></Tooltip>
        </>
    )
}

