import "./StudentCard.css";
import {ProfilePicture} from "../ProfilePicture/ProfilePicture";
import {StudentCardInfo} from "../StudentCardInfo/StudentCardInfo";

const StudentCard = ({student, isSelected, onClickFunc}) => {
    return (
        <div className={'student-card-container ' + (isSelected ? "selected" : "")} onClick={onClickFunc}>
            <div className={"picture-container"}>
                <ProfilePicture image={"https://chedvatanesher.com/" + student.img_name}></ProfilePicture>
            </div>
            <hr className={'student-card-hr'}/>
            <StudentCardInfo student={student}></StudentCardInfo>
        </div>
    );
};

export default StudentCard;