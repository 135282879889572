export const StudentSearch = ({students, filteredState}) => {
    return (
        <div>
            <input type="text" className="form-control" placeholder="חיפוש תלמיד" onChange={(e) => {
                const searchInput = e.target.value.trim();
                filteredState(students.filter((student) => {
                    return student.first_name.includes(searchInput) || student.last_name.includes(searchInput) || (student.first_name + " " + student.last_name).includes(searchInput);
                }));
            }} />
        </div>
    );
}